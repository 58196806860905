import { HttpError } from 'gallerydigitalassets-v1-client'
import { galleryAxiosClient } from 'store/client'
import { keysToCamel } from 'utils/object'

export const getReleaseInfoApi = async (xTgtLanId: string) => {
  try {
    const { data } = await galleryAxiosClient.get('/release/dashboard')
    const camelCaseData = keysToCamel(data)
    return camelCaseData
  } catch (error) {
    if (error instanceof HttpError) {
      throw error
    } else {
      console.error(error)
    }
  }
}
