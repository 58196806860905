import { createSlice } from '@reduxjs/toolkit'
import { IReleaseState } from './types'

const initialState: IReleaseState = {
  releaseInformation: [],
}

const releaseSlice = createSlice({
  name: 'release',
  initialState,
  reducers: {
    getReleaseInfoReducer(state) {},
    setReleaseInfoReducer(state, action) {
      state.releaseInformation = action.payload
    },
  },
})

export const { getReleaseInfoReducer, setReleaseInfoReducer } = releaseSlice.actions

export default releaseSlice.reducer
