import React, { useEffect, useState } from 'react'
import BasePage from 'components/pages/BasePage/BasePage'
import { Button, Grid, Heading, Tabs } from '@enterprise-ui/canvas-ui-react'
import CollectionFeed from 'components/feeds/CollectionFeed/CollectionFeed'
import './Collections.scss'
import './CollectionSearchBar'
import { BreadCrumbPriority, CollectionSet, CollectionSort, ICollectionResult, useAuth, useCollections } from 'store'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { capitalize } from 'lodash'
import { BREAD_CRUMB_VARS } from 'config'
import CollectionSearchBar from './CollectionSearchBar'

const CollectionsPage: React.FC = () => {
  const {
    ownCollections,
    publicCollections,
    favoriteCollections,
    getPublicCollections,
    getOwnCollections,
    resetMyCollection,
    resetPublicCollection,
    getFavoriteCollections,
    resetFavoriteCollection,
    getCollectionSortOrder,
  } = useCollections()
  const { set } = useParams<{ set: CollectionSet }>()
  const { GROUPED_COLLECTIONS } = BREAD_CRUMB_VARS
  const crumb = {
    id: GROUPED_COLLECTIONS,
    name: `${capitalize(set)} Collections`,
    href: `/collections/${set}`,
    priority: BreadCrumbPriority.Medium,
  }

  const [searchParams, setSearchParams] = useSearchParams()
  const [nextPage, setNextPage] = useState(0)
  const { canUserCreateContent } = useAuth()
  const [currentTab, setCurrentTab] = useState<CollectionSet>(set!)
  const [collection, setCollection] = useState<ICollectionResult>()
  const [placeHolderText, setPlaceHolderText] = useState<string>('')

  const [searchQuery, setSearchQuery] = useState<string>('')
  const [collectionSort, setCollectionSort] = useState<CollectionSort>(CollectionSort.LATEST_DESC)

  const navigate = useNavigate()

  const launchCreateCollectionModal = () => {
    searchParams.set('modal', 'create-collection')
    setSearchParams(searchParams)
  }

  const isUserCreator = canUserCreateContent()

  const getMoreCollections = async (size: number) => {
    switch (set) {
      case CollectionSet.MY:
        getOwnCollections(searchQuery, nextPage, size, ownCollections.criteria?.sort)
        break
      case CollectionSet.FAV:
        getFavoriteCollections(searchQuery, nextPage, size, favoriteCollections.criteria?.sort)
        break
      case CollectionSet.PUB:
        getPublicCollections(nextPage, size, publicCollections.criteria?.sort, searchQuery)
        break
    }
  }

  const currentCollections = () => {
    switch (set) {
      case CollectionSet.MY:
        return ownCollections
      case CollectionSet.PUB:
        return publicCollections
      default:
        return favoriteCollections
    }
  }

  const resetCollection = (currentTab: CollectionSet, selectedTab: string) => {
    if (currentTab !== selectedTab) {
      setNextPage(0)
      switch (currentTab) {
        case CollectionSet.MY:
          return resetMyCollection()
        case CollectionSet.PUB:
          return resetPublicCollection()
        default:
          return resetFavoriteCollection()
      }
    }
  }

  useEffect(() => {
    const sortOrder = getCollectionSortOrder(collectionSort)
    switch (set) {
      case CollectionSet.MY:
        resetMyCollection()
        getOwnCollections(searchQuery, nextPage, 25, sortOrder)
        setCollection(ownCollections)
        break
      case CollectionSet.PUB:
        resetPublicCollection()
        getPublicCollections(nextPage, 25, sortOrder, searchQuery)
        setCollection(publicCollections)
        break
      default:
        resetFavoriteCollection()
        getFavoriteCollections(searchQuery, nextPage, 25, sortOrder)
        setCollection(favoriteCollections)
        break
    }
  }, [searchQuery, collectionSort])

  useEffect(() => {
    switch (set) {
      case CollectionSet.MY:
        setCollection(ownCollections)
        setCurrentTab(set)
        setPlaceHolderText('Search My Collections')
        break
      case CollectionSet.PUB:
        setCollection(publicCollections)
        setCurrentTab(set!)
        setPlaceHolderText('Search Public Collections')
        break
      default:
        setCollection(favoriteCollections)
        setCurrentTab(set!)
        setPlaceHolderText('Search Favorite Collections')
    }

    if (!currentCollections().isLoading) getMoreCollections(15)
    return () => {
      resetPublicCollection()
      resetMyCollection()
      resetFavoriteCollection()
    }
  }, [set])

  return (
    <BasePage crumbs={crumb}>
      <Grid.Container direction="column" className="centered-feed-content">
        <Grid.Item xs={12}>
          <Grid.Container className="hc-pt-lg hc-pb-xl" align="center" justify="space-between">
            <Grid.Item>
              <Heading data-testid="main-collection-title" size={2} className="hc-clr-contrast">
                Collections
              </Heading>
            </Grid.Item>
            {isUserCreator && (
              <Grid.Item size={10} className="hc-mr-none">
                <Button
                  className="hc-fs-xs hc-ml-dense"
                  data-testid="collection-page-new-collection-btn"
                  type="primary"
                  onClick={launchCreateCollectionModal}
                >
                  Create new collection
                </Button>
              </Grid.Item>
            )}
          </Grid.Container>
          {isUserCreator && (
            <Grid.Container id="myCollections" className="hc-pv-md" justify="space-between">
              <Grid.Item>
                <Tabs
                  disableAdaptive
                  size="expanded"
                  data-testid="collections-page-tab"
                  activeTab={currentTab}
                  onTabSelect={(event: unknown, tab: any) => {
                    resetCollection(currentTab, tab.name)
                    setCurrentTab(tab.name)
                    navigate(`/collections/${tab.name}`)
                  }}
                >
                  <Tabs.Item
                    name={CollectionSet.FAV}
                    data-testid={'fav-collection-tab'}
                    disabled={currentCollections().isLoading}
                  >
                    <strong className="hc-fs-xs"> Favorite Collections </strong>
                  </Tabs.Item>
                  <Tabs.Item
                    data-testid={'my-collection-tab'}
                    disabled={currentCollections().isLoading}
                    name={CollectionSet.MY}
                  >
                    <strong className="hc-fs-xs"> My Collections </strong>
                  </Tabs.Item>
                  <Tabs.Item
                    data-testid={'public-collection-tab'}
                    disabled={currentCollections().isLoading}
                    name={CollectionSet.PUB}
                  >
                    <strong className="hc-fs-xs"> Public Collections </strong>
                  </Tabs.Item>
                </Tabs>
              </Grid.Item>
              <Grid.Item xs={5}>
                <CollectionSearchBar
                  placeholderText={placeHolderText}
                  setCollectionSort={setCollectionSort}
                  setSearchQuery={setSearchQuery}
                ></CollectionSearchBar>
              </Grid.Item>
            </Grid.Container>
          )}

          <Grid.Container direction="column" id="publicCollections" className="hc-pv-md">
            <Grid.Container direction="row" justify="space-between" align="center" className="hc-ph-sm">
              <Grid.Item>
                <Heading data-testid="subtitle-collections-title" size={4}>
                  {capitalize(currentTab)} Collections
                </Heading>
                <p data-testid="collection-count">
                  Number of collections:{' '}
                  <span data-testid="collection-page-collection-count" style={{ fontWeight: 'bold' }}>
                    {currentCollections().total ? currentCollections().total?.toLocaleString('en-US') : 0}
                  </span>
                </p>
              </Grid.Item>
            </Grid.Container>
            <Grid.Item className="collection-list">
              <div className="scrollable-container">
                {collection && (
                  <CollectionFeed
                    set={set}
                    getMoreCollections={getMoreCollections}
                    collectionResult={currentCollections()}
                    setNextPage={(page: number) => setNextPage(page)}
                    collectionSet={currentTab}
                  />
                )}
              </div>
            </Grid.Item>
          </Grid.Container>
        </Grid.Item>
      </Grid.Container>
    </BasePage>
  )
}

export default CollectionsPage
