import { Anchor, Avatar, Chip, Divider, Grid } from '@enterprise-ui/canvas-ui-react'
import { ClusterData, IReleaseInfo } from 'store'
import ReleaseDashboardAppLinks from './ReleaseDashboardAppLinks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTag } from '@fortawesome/free-solid-svg-icons'

export const NO_CLUSTER_FOUND = 'No cluster found.'
export const VELA_BASE_URL = 'https://vela.prod.target.com/emt/'

export const splitK6Cluster = (input: IReleaseInfo): IReleaseInfo[] => {
  // Create deep copies for two separate objects
  const gdaCluster: IReleaseInfo = { ...input, name: 'galleryk6-gda', clusters: { ...input.clusters, dev: [] } }
  const searchCluster: IReleaseInfo = { ...input, name: 'galleryk6-search', clusters: { ...input.clusters, dev: [] } }

  // Separate 'galleryk6-gda' and 'galleryk6-search' from 'dev'
  input.clusters.dev.forEach(cluster => {
    if (cluster.clusterName === 'galleryk6-gda') {
      gdaCluster.clusters.dev.push(cluster)
    } else if (cluster.clusterName === 'galleryk6-search') {
      searchCluster.clusters.dev.push(cluster)
    }
  })
  return [gdaCluster, searchCluster]
}

export const transformToCompareURL = (commitURL?: string) => {
  return commitURL && commitURL.replace(/\/commit\/.+$/, '/compare')
}

export const transformToPromoteURL = (commitURL?: string) => {
  return commitURL && commitURL.replace(/\/commit\/.+$/, '/releases/new')
}

export const getCompareURL = (clusterOne: ClusterData, clusterTwo: ClusterData) => {
  const baseUrl = transformToCompareURL(clusterOne.commitLink)
  const compareLink = `${baseUrl}/${clusterTwo.commitId}...${clusterOne.commitId}`
  return compareLink
}

export const getPromoteURL = (appName: string, clusterOne: ClusterData, environment?: string) => {
  const baseUrl = transformToPromoteURL(clusterOne.commitLink)
  const title = clusterOne.title || `${environment} promotion`
  const buildNumber = clusterOne.refBuildNumber || clusterOne.buildNumber
  const logParams = `?tag=${appName}-${environment}-candidate-0.1-${buildNumber}&target=${clusterOne.commitId}&title=${title}`
  const promoteLink = `${baseUrl}/${logParams}`
  return promoteLink
}

export const renderCluster = (cluster?: ClusterData | null) => {
  // Check if the cluster has a deployedImage
  if (!cluster?.deployedImage) {
    return <strong>{NO_CLUSTER_FOUND}</strong>
  }

  const { deployedImage, commitLink } = cluster

  // Helper function to extract relevant part of deployedImage
  const extractVersion = (image: string) => {
    if (image.includes('dev')) {
      return `dev${image.split('dev')[1].trim()}`
    } else if (image.includes('stage')) {
      return `stage${image.split('-stage')[1].trim()}`
    } else if (image.includes('prod')) {
      return `prod${image.split('-prod')[1].trim()}`
    }
    return image // Return the original image if no conditions match
  }

  const formattedImage = extractVersion(deployedImage)

  return (
    <Chip avatar size="dense" type="subtle" className="hc-mb-dense hc-bg-grey01">
      <Avatar aria-label="build tag" size="dense" className="hc-bg-contrast">
        <FontAwesomeIcon color="white" icon={faTag} />
      </Avatar>
      {commitLink ? (
        <Anchor href={commitLink} target="_blank" style={{ color: 'white' }}>
          {formattedImage}
        </Anchor>
      ) : (
        <span style={{ color: 'white' }}>{formattedImage}</span>
      )}
    </Chip>
  )
}

export const renderClusterItem = (appName: string, cluster?: ClusterData | null, informationOverload?: boolean) => {
  return (
    <Grid.Item className="hc-pa-dense" xs={2}>
      {renderCluster(cluster)}
      {informationOverload && cluster?.deployedImage && (
        <ReleaseDashboardAppLinks
          repoName={appName}
          clusterName={cluster?.clusterName}
          environment={cluster?.environment}
          deployedDate={cluster?.deployedDate}
        />
      )}
    </Grid.Item>
  )
}

export const renderPromotionDetails = (
  appName: string,
  clusterA?: ClusterData | null,
  clusterB?: ClusterData | null,
  compareLabel?: string,
  promoteLabel?: string,
  environment?: string
) => {
  if (clusterA && clusterB && clusterA.commitId !== clusterB.commitId) {
    return (
      <>
        <Anchor href={getCompareURL(clusterA, clusterB)} target="_blank">
          {compareLabel}
        </Anchor>
        <Divider width={3} />
        <Anchor href={getPromoteURL(appName, clusterA, environment)} target="_blank">
          {promoteLabel}
        </Anchor>
      </>
    )
  }
  return <span>-</span>
}

export const getRepoNameFromProdCommit = (prodCluster?: ClusterData | null) => {
  const sampleLink = prodCluster?.commitLink || ''

  const repoNameMatch = sampleLink.match(/emt\/([^/]+)\/commit/)
  return repoNameMatch ? repoNameMatch[1] : null // Extracts and returns the value between "emt/" and "/commit"
}
