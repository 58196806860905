import { FileView } from 'gallerydigitalassets-v1-client'
import { all, takeLatest, call, put } from 'redux-saga/effects'
import delay from '@redux-saga/delay-p'
import {
  addToast,
  IAsset,
  IUser,
  ICreateAssetFileValues,
  deleteFileAPI,
  processError,
  PageStatus,
  removeToast,
  IAssetActivity,
} from 'store'

import {
  getAssetApi,
  createAssetApi,
  editAssetApi,
  deleteAssetApi,
  getAssetUsersApi,
  addAssetUserApi,
  deleteAssetUserApi,
  addFileToAssetApi,
  downloadAssetApi,
  downloadAssetsApi,
  downloadLinkedAssetsApi,
  getAssetActivityApi,
  process3DFileApi,
} from './api'
import {
  getAssetReducer,
  setAssetReducer,
  createAssetReducer,
  addAssetFileStatusReducer,
  setAssetFileStatusValueReducer,
  clearAssetFileStatusValuesReducer,
  editAssetReducer,
  addFilesToAssetReducer,
  deleteAssetReducer,
  getAssetUsersReducer,
  setAssetUsersReducer,
  addAssetUserReducer,
  deleteAssetUserReducer,
  downloadAssetReducer,
  downloadAssetsReducer,
  downloadLinkedAssetReducer,
  setAssetDetailPageStatus,
  setAssetActivityReducer,
  getAssetActivityReducer,
  setAssetActivityIsLoading,
  process3DFileApiReducer,
} from './slice'
import { STORAGE_KEYS } from '../../config'

export function* getAssetSaga(data: any) {
  yield put(setAssetDetailPageStatus(PageStatus.loading))
  const xTgtLanId: string = localStorage.getItem(STORAGE_KEYS.TGT_LAN_ID)!!
  try {
    const asset: IAsset = yield call(getAssetApi, xTgtLanId, data.payload)
    yield put(setAssetReducer(asset))
    yield put(setAssetDetailPageStatus(PageStatus.success))
  } catch (error) {
    if (error instanceof Error) {
      yield call(processError, error, error.message)
    } else {
      console.log(error)
    }
    yield put(setAssetDetailPageStatus(PageStatus.error))
  }
}

// methods for bulk updating file status on asset create
function* addFileToAssetStatusSaga(data: any) {
  const { file, name } = data
  yield put(addAssetFileStatusReducer({ name, file }))
}

function* updateFileToAssetStatusSaga(data: any) {
  const { assetId, file, name, callTranscoding } = data
  const xTgtLanId: string = localStorage.getItem(STORAGE_KEYS.TGT_LAN_ID)!!
  yield put(setAssetFileStatusValueReducer({ name, status: 'UPLOADING' }))
  yield call(addFileToAssetApi, xTgtLanId, assetId, file, name, callTranscoding)
  yield put(setAssetFileStatusValueReducer({ name, status: 'UPLOADED' }))
}

function* createAssetSaga(data: any) {
  // clear previous upload status data
  yield put(clearAssetFileStatusValuesReducer())
  const { name, files, isAssetPublic, taxonomyId, onSuccessCallback, onErrorCallback } = data.payload
  const xTgtLanId: string = localStorage.getItem(STORAGE_KEYS.TGT_LAN_ID)!!
  let assetFile: ICreateAssetFileValues = files[0]
  let filesToUpload: ICreateAssetFileValues[] = []
  let startPipelineOnAssetCreate = 'true'

  if (files.length > 1) {
    startPipelineOnAssetCreate = 'false'
    filesToUpload = files.slice(1)
    const fileCalls = files.map((file: ICreateAssetFileValues) => {
      return call(addFileToAssetStatusSaga, { name: file.name, file: file.file })
    })
    yield all(fileCalls)
  }

  try {
    // calls createApi with first file
    yield put(setAssetFileStatusValueReducer({ name: assetFile.name, status: 'UPLOADING' }))
    const asset: IAsset = yield call(
      createAssetApi,
      xTgtLanId,
      name,
      assetFile.file,
      isAssetPublic,
      taxonomyId,
      'NONE',
      startPipelineOnAssetCreate
    )
    yield put(setAssetFileStatusValueReducer({ name: assetFile.name, status: 'UPLOADED' }))
    const lastFilesToUpload = filesToUpload.splice(-1)
    // uploads subsequent files
    if (filesToUpload.length) {
      const fileCalls = filesToUpload.map(file => {
        return call(updateFileToAssetStatusSaga, {
          assetId: asset.id,
          file: file.file,
          name: file.name,
          callTranscoding: false,
        })
      })
      yield all(fileCalls)
    }
    if (lastFilesToUpload.length) {
      const fileCalls = lastFilesToUpload.map(file => {
        return call(updateFileToAssetStatusSaga, {
          assetId: asset.id,
          file: file.file,
          name: file.name,
          callTranscoding: true,
        })
      })
      yield all(fileCalls)
    }
    if (onSuccessCallback !== undefined) {
      yield call(onSuccessCallback, asset.id)
    }
    yield put(addToast({ color: 'success', headingText: 'Success', message: 'New asset created' }))
  } catch (error) {
    if (onErrorCallback !== undefined) {
      yield call(onErrorCallback)
    }
    if (error instanceof Error) {
      yield call(processError, error, error.message)
    } else {
      console.log(error)
    }
  }
}

function* editAssetSaga(data: any) {
  const { assetId, editRequest, filesToAdd, filesToRemove, onSuccess } = data.payload
  const { initialAssetName, newAssetName, initialIsPublic, newIsPublic, initialTaxonomyId, newTaxonomyId } = editRequest
  const xTgtLanId: string = localStorage.getItem(STORAGE_KEYS.TGT_LAN_ID)!!
  try {
    // make edit asset call if any data changes were made
    if (initialAssetName !== newAssetName || initialIsPublic !== newIsPublic || initialTaxonomyId !== newTaxonomyId) {
      yield call(editAssetApi, xTgtLanId, assetId, {
        assetName: newAssetName,
        makePublic: newIsPublic,
        taxonomyId: newTaxonomyId,
      })
    }
    // add and update redux state, and add files to asset if any files were added in modal
    if (filesToAdd.length) {
      yield call(addFilesToAssetSaga, { assetId, files: filesToAdd })
    }
    if (filesToRemove.length) {
      const fileIds = filesToRemove.map((file: FileView) => {
        return file.id
      })
      yield call(deleteFileAPI, xTgtLanId, fileIds, assetId)
    }
    yield call(onSuccess)
    yield call(getAssetSaga, { payload: assetId })
    yield put(
      addToast({
        color: 'success',
        headingText: 'Success',
        message: 'Asset Successfully Updated',
      })
    )
  } catch (error) {
    if (error instanceof Error) {
      yield call(processError, error, error.message)
    } else {
      console.log(error)
    }
  }
}

// TODO: ONCE ADD AND REMOVE FILE ENDPOINTS DONE, MOVE ALL INTO EDIT ASSET SAGA WITH ONE CALL FROM MODAL
function* addFilesToAssetSaga(data: any) {
  // clear previous upload status data
  yield put(clearAssetFileStatusValuesReducer())
  const { assetId, files } = data
  try {
    // add files to status reducer for status indicator in modal
    const fileAddCalls = files.map((file: ICreateAssetFileValues) => {
      return call(addFileToAssetStatusSaga, { name: file.name, file: file.file })
    })
    yield all(fileAddCalls)
    const fileUpdateCalls = files.map((file: any, i: any) => {
      return call(updateFileToAssetStatusSaga, { assetId, file: file.file, name: file.name, callTranscoding: true })
    })
    yield all(fileUpdateCalls)
    yield put(getAssetReducer)
  } catch (error) {
    if (error instanceof Error) {
      yield call(processError, error, error.message)
    } else {
      console.log(error)
    }
  }
}

function* deleteAssetSaga(data: any) {
  const { id, pathname, navigate } = data.payload
  const xTgtLanId: string = localStorage.getItem(STORAGE_KEYS.TGT_LAN_ID)!!
  try {
    yield call(deleteAssetApi, xTgtLanId, id)
    yield put(
      addToast({
        color: 'success',
        headingText: 'Asset Deleted',
        message: `The ${id} asset has been successfully deleted.`,
      })
    )
    // Redirect to homepage if deeplinked to page, else go back to previous page
    if (!pathname) {
      yield call(navigate, '/')
    } else {
      yield call(navigate, -1)
    }
  } catch (error) {
    if (error instanceof Error) {
      yield call(processError, error, error.message)
    } else {
      console.log(error)
    }
  }
}

function* getAssetUsersSaga(data: any) {
  try {
    const xTgtLanId: string = localStorage.getItem(STORAGE_KEYS.TGT_LAN_ID)!!
    const assetUsers: IUser[] = yield call(getAssetUsersApi, xTgtLanId, data.payload.assetId, data.payload.permission)
    yield put(setAssetUsersReducer(assetUsers))
  } catch (error) {
    if (error instanceof Error) {
      yield call(processError, error, error.message)
    } else {
      console.log(error)
    }
  }
}

function* addAssetUserSaga(data: any) {
  try {
    const xTgtLanId: string = localStorage.getItem(STORAGE_KEYS.TGT_LAN_ID)!!
    yield call(addAssetUserApi, xTgtLanId, data.payload.assetId, data.payload.userId, data.payload.access)
    yield put(addToast({ color: 'success', headingText: 'Success', message: 'Asset manager updated' }))
  } catch (error) {
    if (error instanceof Error) {
      yield call(processError, error, error.message)
    } else {
      console.log(error)
    }
  }
}

function* deleteAssetUserSaga(data: any) {
  const { assetId, userId } = data.payload
  const xTgtLanId: string = localStorage.getItem(STORAGE_KEYS.TGT_LAN_ID)!!
  try {
    yield call(deleteAssetUserApi, xTgtLanId, assetId, userId)
    yield put(
      addToast({
        color: 'success',
        headingText: 'Success',
        message: 'Asset manager updated',
      })
    )
    yield call(getAssetUsersSaga, { payload: { assetId: assetId, permission: 'EDIT' } })
  } catch (error) {
    if (error instanceof Error) {
      yield call(processError, error, error.message)
    } else {
      console.log(error)
    }
  }
}

function* downloadAssetSaga(data: any) {
  const xTgtLanId: string = localStorage.getItem(STORAGE_KEYS.TGT_LAN_ID)!!
  const { assetId } = data.payload
  const downloadToast = {
    color: 'success',
    headingText: 'Asset Downloading',
    message: 'Asset Download Initiated, Please Wait',
    autoHideDuration: 10000,
  }
  try {
    yield put(addToast(downloadToast))
    yield call(downloadAssetApi, xTgtLanId, assetId)
  } catch (error) {
    yield call(downloadAssetsSaga, { payload: { assetIds: [assetId] } })
    if (error.isAxiosError) {
      const errorResponse = { message: `${error.response.statusText}` }
      const errorMessage = errorResponse.message + ' Asset async download triggered.'
      yield call(processError, error, errorMessage)
    } else if (error instanceof Error) {
      yield call(processError, error, error.message + ' Asset async download triggered.')
    } else {
      console.log(error)
    }
  } finally {
    yield put(removeToast(downloadToast))
  }
}

function* downloadLinkedAssetSaga(data: any) {
  const xTgtLanId: string = localStorage.getItem(STORAGE_KEYS.TGT_LAN_ID)!!
  const { assetId } = data.payload
  const downloadToast = {
    color: 'success',
    headingText: 'Linked Asset(s) Downloading',
    message: 'Download Initiated. Files will be sent via email.',
    autoHideDuration: 9000,
  }
  try {
    yield put(addToast(downloadToast))
    yield call(downloadLinkedAssetsApi, xTgtLanId, assetId)
  } catch (error) {
    if (error.isAxiosError) {
      const errorResponse = { message: `${error.response.statusText}` }
      const errorMessage = errorResponse.message
      yield call(processError, error, errorMessage)
    } else if (error instanceof Error) {
      yield call(processError, error, error.message)
    } else {
      console.log(error)
    }
  } finally {
    yield put(removeToast(downloadToast))
  }
}

function* downloadAssetsSaga(data: any) {
  const { assetIds } = data.payload
  const xTgtLanId: string = localStorage.getItem(STORAGE_KEYS.TGT_LAN_ID)!!
  const downloadToast = {
    color: 'success',
    headingText: 'Assets downloading',
    message: 'Assets will be sent to your email when completed.',
    autoHideDuration: 10000,
  }
  try {
    yield put(addToast(downloadToast))
    yield call(downloadAssetsApi, xTgtLanId, assetIds)
    yield delay(6000)
  } catch (error) {
    if (error.isAxiosError) {
      const errorResponse = { message: `${error.response.statusText}` }
      const errorMessage = errorResponse.message
      yield call(processError, error, errorMessage)
    } else if (error instanceof Error) {
      yield call(processError, error, error.message)
    } else {
      console.log(error)
    }
  } finally {
    yield put(removeToast(downloadToast))
  }
}

function* getAssetActivitySaga(action: any) {
  const { assetActivityServer, assetId } = action.payload
  try {
    yield put(setAssetActivityIsLoading(true))
    const assetActivity: IAssetActivity = yield call(getAssetActivityApi, assetActivityServer, assetId)
    yield put(setAssetActivityReducer(assetActivity))
    yield put(setAssetActivityIsLoading(false))
  } catch (error) {
    if (error instanceof Error) {
      yield put(setAssetActivityIsLoading(false))
      yield call(processError, error, error.message)
    } else {
      console.log(error)
    }
    yield put(setAssetDetailPageStatus(PageStatus.error))
  }
}

function* process3DFileApiSaga(data: any) {
  const { assetId, pipelineId } = data.payload
  const xTgtLanId: string = localStorage.getItem(STORAGE_KEYS.TGT_LAN_ID)!!
  try {
    yield call(process3DFileApi, xTgtLanId, assetId, pipelineId)
  } catch (error) {
    if (error instanceof Error) {
      yield call(processError, error, error.message)
    } else {
      console.error(error)
    }
  }
}

export default function* watchAssetSagas() {
  yield all([
    takeLatest(getAssetReducer.type, getAssetSaga),
    takeLatest(createAssetReducer.type, createAssetSaga),
    takeLatest(editAssetReducer.type, editAssetSaga),
    takeLatest(deleteAssetReducer.type, deleteAssetSaga),
    takeLatest(getAssetUsersReducer.type, getAssetUsersSaga),
    takeLatest(addAssetUserReducer.type, addAssetUserSaga),
    takeLatest(deleteAssetUserReducer.type, deleteAssetUserSaga),
    takeLatest(addFilesToAssetReducer.type, addFilesToAssetSaga),
    takeLatest(downloadAssetReducer.type, downloadAssetSaga),
    takeLatest(downloadAssetsReducer.type, downloadAssetsSaga),
    takeLatest(downloadLinkedAssetReducer.type, downloadLinkedAssetSaga),
    takeLatest(getAssetActivityReducer.type, getAssetActivitySaga),
    takeLatest(process3DFileApiReducer.type, process3DFileApiSaga),
  ])
}
