import { all, put, takeLatest } from 'redux-saga/effects'
import { startGallery } from './rootReducer'
import {
  assetSagas,
  authSagas,
  collectionSagas,
  dialogSagas,
  filesSagas,
  searchSagas,
  tagsSagas,
  taxonomySagas,
  userSagas,
  releaseSagas,
  getAccessToken,
} from 'store'

function* startGallerySaga() {
  yield put(getAccessToken())

  // Get user from endpoint.
}

export default function* galleryWatcher() {
  yield all([
    takeLatest(startGallery.type, startGallerySaga),
    assetSagas(),
    collectionSagas(),
    filesSagas(),
    searchSagas(),
    tagsSagas(),
    taxonomySagas(),
    userSagas(),
    dialogSagas(),
    releaseSagas(),
    authSagas(),
  ])
}
