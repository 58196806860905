import React from 'react'
import { Grid, Heading } from '@enterprise-ui/canvas-ui-react'

export const DashboardAppListHeader: React.FC = () => {
  return (
    <Grid.Container spacing="none">
      <Grid.Item xs={2}>
        <div className="hc-pa-dense hc-bg-grey05">
          <Heading size={5}>App</Heading>
        </div>
      </Grid.Item>
      <Grid.Item xs={2}>
        <div className="hc-pa-dense hc-bg-grey05">
          <Heading size={5}>Dev</Heading>
        </div>
      </Grid.Item>
      <Grid.Item xs={2}>
        <div className="hc-pa-dense hc-bg-grey05">
          <Heading size={5}>→ Stage promotion →</Heading>
        </div>
      </Grid.Item>
      <Grid.Item xs={2}>
        <div className="hc-pa-dense hc-bg-grey05">
          <Heading size={5}>Stage</Heading>
        </div>
      </Grid.Item>
      <Grid.Item xs={2}>
        <div className="hc-pa-dense hc-bg-grey05">
          <Heading size={5}>→ Prod promotion →</Heading>
        </div>
      </Grid.Item>
      <Grid.Item xs={2}>
        <div className="hc-pa-dense hc-bg-grey05">
          <Heading size={5}>Prod</Heading>
        </div>
      </Grid.Item>
    </Grid.Container>
  )
}

export default DashboardAppListHeader
