import { useSelector, useDispatch } from 'react-redux'
import { useEffect } from 'react'
import {
  getAssetReducer,
  setAssetReducer,
  createAssetReducer,
  editAssetReducer,
  deleteAssetReducer,
  getAssetUsersReducer,
  addAssetUserReducer,
  deleteAssetUserReducer,
  setAssetDetailPageStatus,
  setAssetUsersReducer,
  downloadAssetReducer,
  downloadAssetsReducer,
  setUserAssetPermissionsReducer,
  downloadLinkedAssetReducer,
  setAssetActivityReducer,
  getAssetActivityReducer,
  process3DFileApiReducer,
} from './slice'
import {
  assetDetailPageAssetSelector,
  assetDetailPageStatusSelector,
  assetUsersSelector,
  linkedAssetSelector,
  newFilesStatusValuesSelector,
  userAssetPermissionsSelector,
  assetActivitySelector,
  assetActivityIsLoadingSelector,
} from './selectors'
import { IAsset, ICreateAssetFileValues, IEditAssetModalFile, IUserAssetPermissions } from './types'
import { useNavigate, useLocation } from 'react-router-dom'
import { get, some } from 'lodash'
import { PageStatus } from 'store/client'
import { PermissionView } from 'gallerydigitalassets-v1-client'
import { useAppEnv } from 'store/env/hook'
import { useEnv } from '@praxis/component-runtime-env'
import { ITag } from '../tags'
import { useTaxonomy } from '../taxonomy'

export const useAsset = () => {
  const assetDetailPageAsset = useSelector(assetDetailPageAssetSelector)
  const assetUsers = useSelector(assetUsersSelector)
  const newFilesStatusValues = useSelector(newFilesStatusValuesSelector)
  const assetDetailPageStatus: PageStatus = useSelector(assetDetailPageStatusSelector)
  const userAssetPermissions: IUserAssetPermissions = useSelector(userAssetPermissionsSelector)
  const linkedAssets: IAsset[] | undefined = useSelector(linkedAssetSelector)
  const assetActivity = useSelector(assetActivitySelector)
  const assetActivityIsLoading = useSelector(assetActivityIsLoadingSelector)
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const { environment } = useAppEnv()
  const { assetStatus, assetActivityServer } = useEnv()
  const { taxonomies, getTaxonomyNodeById } = useTaxonomy()

  // captures path of previous page, if deep-linked to page, pathname === ''
  const pathname: string = get(location, 'state.location.pathname', '')

  const getAsset = async (id: string) => {
    dispatch(getAssetReducer(id))
  }

  const getAssetStatus = (assetTags: ITag[] | undefined, taxonomyId: string) => {
    const assetTaxonomy = getTaxonomyNodeById(taxonomies, taxonomyId)
    if (assetStatus.taxonomies.includes(assetTaxonomy.name.toLowerCase())) {
      const tcinTagPresent = assetTags?.filter((assetTag: ITag) => assetTag.tag.key.toLowerCase() === 'tcin')
      if (tcinTagPresent && tcinTagPresent.length > 0) {
        const assetStatusTags = assetTags?.filter((assetTag: ITag) => assetTag.tag.key === assetStatus.key)
        const activeStatusTags = assetStatusTags?.filter(
          (assetStatusTag: ITag) => assetStatusTag.tag.value === assetStatus.activeValue
        ).length
        return activeStatusTags ? (activeStatusTags > 0 ? 'Active' : 'Inactive') : 'Inactive'
      }
    }
    return ''
  }

  const setAsset = async (asset: IAsset | undefined) => {
    dispatch(setAssetReducer(asset))
  }

  const setAssetDetailStatus = (status: PageStatus) => {
    dispatch(setAssetDetailPageStatus(status))
  }

  useEffect(() => {
    const asset = assetDetailPageAsset
    // allows editing and deleting of asset if user is organization admin
    const isOrgAdmin = asset?.permissions.filter(
      (permission: PermissionView) =>
        permission.access === PermissionView.AccessEnum.OrganizationAdmin ||
        permission.access === PermissionView.AccessEnum.Admin
    )

    // allows editing and deleting of asset if user is asset creator
    const isAssetCreator = asset?.permissions.filter(
      (permission: PermissionView) => permission.access === PermissionView.AccessEnum.AssetCreator
    )

    // allows editing and deleting of asset if user is asset manager

    const assetManager = asset?.permissions.filter(
      (permission: PermissionView) => permission.access === PermissionView.AccessEnum.AssetManager
    )

    const payload: IUserAssetPermissions = {
      userCanEditAndDeleteAsset: some(isOrgAdmin) || some(isAssetCreator),
      userIsAssetManager: some(assetManager),
    }

    dispatch(setUserAssetPermissionsReducer(payload))
  }, [assetDetailPageAsset])

  const createAsset = async (
    name: string,
    files: ICreateAssetFileValues[],
    isAssetPublic: boolean,
    taxonomyId: string,
    onSuccessCallback: (assetId: string) => void,
    onErrorCallback: () => void
  ) => {
    const payload = {
      name,
      files,
      isAssetPublic,
      taxonomyId,
      onSuccessCallback,
      onErrorCallback,
    }
    dispatch(createAssetReducer(payload))
  }

  const process3DFile = async (assetId: string[], pipelineId?: string) => {
    const payload = {
      assetId,
      pipelineId,
    }
    dispatch(process3DFileApiReducer(payload))
  }

  const editAsset = async (
    assetId: string,
    editRequest: {
      initialAssetName: string
      newAssetName: string
      initialIsPublic: boolean
      newIsPublic: boolean
      initialTaxonomyId: string
      newTaxonomyId: string
    },
    filesToAdd: IEditAssetModalFile[],
    filesToRemove: IEditAssetModalFile[],
    onSuccess: () => void
  ) => {
    const payload = {
      assetId,
      editRequest,
      filesToAdd,
      filesToRemove,
      onSuccess,
    }
    dispatch(editAssetReducer(payload))
  }

  const deleteAsset = async (id: string) => {
    const payload = {
      id,
      pathname,
      navigate,
    }
    dispatch(deleteAssetReducer(payload))
  }

  const getAssetUsers = async (assetId: string, permission: string = 'EDIT') => {
    const payload = {
      assetId,
      permission,
    }
    dispatch(getAssetUsersReducer(payload))
  }

  const deleteAssetUser = async (assetId: string, userId: string) => {
    const payload = {
      assetId,
      userId,
    }
    dispatch(deleteAssetUserReducer(payload))
  }

  const addAssetUser = async (
    assetId: string,
    userId: string[],
    access:
      | 'NONE'
      | 'ADMIN'
      | 'ORGANIZATION_ADMIN'
      | 'WORKFLOW_ADMIN'
      | 'ORGANIZATION_ASSET_CREATOR'
      | 'ORGANIZATION_ASSET_MANAGER'
      | 'ORGANIZATION_ASSET_VIEWER'
      | 'ORGANIZATION_GALLERY_VIEWER'
      | 'ORGANIZATION_GALLERY_MANAGER'
      | 'ORGANIZATION_GALLERY_CREATOR'
      | 'GALLERY_CREATOR'
      | 'GALLERY_MANAGER'
      | 'GALLERY_VIEWER'
      | 'ASSET_CREATOR'
      | 'ASSET_MANAGER'
      | 'ASSET_VIEWER'
      | 'WORKFLOW_REVIEWER'
      | 'WORKFLOW_RENDER_CREATOR'
      | 'WORKFLOW_DEFAULT'
  ) => {
    const payload = {
      assetId,
      userId,
      access,
    }
    dispatch(addAssetUserReducer(payload))
  }

  const resetUsers = async () => {
    dispatch(setAssetUsersReducer([]))
  }

  const downloadAsset = async (assetId: string) => {
    const payload = { assetId }
    dispatch(downloadAssetReducer(payload))
  }

  const downloadLinkedAssets = async (assetId: string) => {
    const payload = { assetId }
    dispatch(downloadLinkedAssetReducer(payload))
  }

  const downloadAssetsAsync = async (e: React.MouseEvent<HTMLSpanElement>, assetIds: string[]) => {
    e.preventDefault()
    const payload = { assetIds }
    dispatch(downloadAssetsReducer(payload))
  }

  const downloadAssetLocally = (e: React.MouseEvent<HTMLSpanElement>, assetId: string) => {
    e.preventDefault()
    downloadAsset(assetId)
  }

  const downloadAssetsLocally = (e: React.MouseEvent<HTMLSpanElement>, assetIds: string[]) => {
    e.preventDefault()
    const payload = { assetIds }
    dispatch(downloadAssetsReducer(payload))
  }

  const sendAssetToGD = (assetId: string) => {
    window.location.href = `gallery://${environment}/asset/${assetId}`
  }

  const getAssetActivity = async (assetId: string) => {
    dispatch(getAssetActivityReducer({ assetActivityServer: assetActivityServer, assetId: assetId }))
  }

  const setAssetActivity = async (assetId: string) => {
    dispatch(setAssetActivityReducer({ assetActivityServer: assetActivityServer, assetId: assetId }))
  }

  return {
    getAsset,
    setAsset,
    setAssetDetailStatus,
    createAsset,
    editAsset,
    deleteAsset,
    getAssetUsers,
    deleteAssetUser,
    addAssetUser,
    assetDetailPageAsset,
    assetDetailPageStatus,
    assetUsers,
    newFilesStatusValues,
    userAssetPermissions,
    linkedAssets,
    resetUsers,
    downloadAsset,
    downloadAssetLocally,
    downloadAssetsLocally,
    downloadLinkedAssets,
    sendAssetToGD,
    downloadAssetsAsync,
    getAssetStatus,
    setAssetActivity,
    getAssetActivity,
    assetActivity,
    assetActivityIsLoading,
    process3DFile,
  }
}
