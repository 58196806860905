import BasePage from 'components/pages/BasePage/BasePage'
import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import './SearchPage.scss'
import AssetFeed from 'components/feeds/AssetFeed/AssetFeed'
import { useDispatch } from 'react-redux'
import { BreadCrumbPriority, replaceCrumb, useSearch, ZeroStateToShowEnum, IFilterAction } from 'store'
import FilterContainer from 'components/pages/SearchPage/filters/FilterContainer'
import { Grid, Chip } from '@enterprise-ui/canvas-ui-react'
import SearchBar from 'components/SearchBar/SearchBar'
import { convertToTitle } from 'utils/string'
import { some } from 'lodash'

const SearchPage = () => {
  const location = useLocation()
  const {
    searchPageAssetsAreLoading: isLoading,
    searchPageAssets,
    searchError,
    setSearchError,
    infiniteScrollMethod,
    querySearchCriteria,
    dispatchSearch,
    setSearchTab,
    setSearchValue,
    clearStore,
    updateSearchCriteriaFilters,
    currentFilterChips,
  } = useSearch()
  const dispatch = useDispatch()
  const crumb = {
    name: 'Search',
    href: `/search`,
    search: location.search,
    priority: BreadCrumbPriority.High,
  }

  useEffect(() => {
    const searchCrumb = {
      name: 'Search',
      href: `/search`,
      search: location.search,
      priority: BreadCrumbPriority.High,
    }
    dispatch(replaceCrumb(searchCrumb))
  }, [location.search])

  // updates search results after deleting asset and also provide deep linking
  useEffect(() => {
    clearStore()
    dispatchSearch({
      q: querySearchCriteria.q as string | undefined,
      p: 0,
      f: querySearchCriteria.f as { [key: string]: string[] } | undefined,
      t: querySearchCriteria.t as string | undefined,
      size: querySearchCriteria.size as number | undefined,
    })
    setSearchTab((querySearchCriteria.t as string) || 'All')
    setSearchValue((querySearchCriteria.q as string) || '')

    return () => {
      setSearchError(false)
    }
  }, [])

  // This feature was causing some issues with state being out of sync between filters and search queries. Opting to leave this out until we have time to work out a solve.
  // useEffect(() => {
  //   dispatchSearch({
  //     q: querySearchCriteria.q as string | undefined,
  //     p: 0,
  //     f: querySearchCriteria.f as { [key: string]: string[] } | undefined,
  //     t: querySearchCriteria.t as string | undefined,
  //     size: querySearchCriteria.size as number | undefined,
  //   })
  // }, [exactSearch])

  return (
    <BasePage crumbs={crumb}>
      <Grid.Container spacing="none">
        <Grid.Item xs={5} sm={5} md={4} lg={3} xl={3} className="filters hc-pl-normal hc-pt-none hc-pb-none">
          <FilterContainer />
        </Grid.Item>
        <Grid.Item xs className="asset-feed-content hc-pa-none">
          <Grid.Container className="with-filters heading hc-pt-expanded" direction="column" justify="space-around">
            <Grid.Item xs={12}>
              <Grid.Container justify="center" align="center">
                <Grid.Item>
                  <SearchBar className="search-page-search" data-testid="search-page-overview" showSelect={true} />
                </Grid.Item>
              </Grid.Container>
            </Grid.Item>
            <Grid.Item xs={12}>
              <div className="container-width-control">
                {currentFilterChips &&
                  some(Object.keys(currentFilterChips)) &&
                  Object.keys(currentFilterChips)!!.map((currentFilterChip: string, index) => {
                    const subFilters = currentFilterChips[currentFilterChip]
                    return subFilters.map(subFilter => (
                      <Chip
                        key={`${index}-search-results-chip`}
                        role="false"
                        data-testid="gallery-chip"
                        className="gallery-chips-dense hc-mb-xs"
                        onRequestDelete={() => {
                          updateSearchCriteriaFilters(IFilterAction.Remove, currentFilterChip, subFilter)
                        }}
                      >
                        <span className="bold hc-ma-none hc-pl-dense hc-pr-none hc-fs-xs">
                          {convertToTitle(currentFilterChip, '_')}:
                        </span>
                        <span className="hc-pa-none">{' ' + subFilter.trim().toUpperCase()}</span>
                      </Chip>
                    ))
                  })}
              </div>
            </Grid.Item>
            <AssetFeed
              pageName="searchPage"
              isLoading={isLoading}
              assets={searchPageAssets.assets}
              totalCount={searchPageAssets.total}
              zeroStateToShow={ZeroStateToShowEnum.SEARCHPAGE}
              infiniteScrollMethod={infiniteScrollMethod}
              disableInfinite={searchError}
              showResultsLabel
            />
          </Grid.Container>
        </Grid.Item>
      </Grid.Container>
    </BasePage>
  )
}

export default SearchPage
