import React from 'react'
import { Anchor, TargetLogo, Tooltip } from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, { CalendarIcon } from '@enterprise-ui/icons'

interface ReleaseDashboardAppLinksProps {
  repoName: string
  clusterName?: string
  environment?: string
  deployedDate?: string
}

const ReleaseDashboardAppLinks: React.FC<ReleaseDashboardAppLinksProps> = ({
  repoName,
  clusterName,
  environment,
  deployedDate,
}) => {
  if (repoName === 'galleryk6-gda' || repoName === 'galleryk6-search') repoName = 'galleryk6'
  const logParams = `?relative=300000&q=labels.application="${repoName}"+AND+labels.cluster="${clusterName}"+AND+labels.environment="${environment}"+AND+ container.name="app"`
  const [date, time] = deployedDate ? deployedDate.split(' ') : ['', '']
  const updatedDeployedDate = `Deployed on: ${date} at ${time}`
  return (
    <div className="hc-pa-none">
      <Tooltip content={updatedDeployedDate} location="right">
        <EnterpriseIcon icon={CalendarIcon} size="sm" />
      </Tooltip>
      &nbsp;
      <Tooltip content="TAP" location="right">
        <Anchor
          target="_blank"
          href={`https://tap.prod.platform.target.com/a/${repoName}/containers/clusters/${environment}/${clusterName}/pipelines`}
        >
          <TargetLogo />
        </Anchor>
      </Tooltip>
      &nbsp;
      <Tooltip content="Logs" location="right">
        <Anchor
          target="_blank"
          href={`https://tap.prod.platform.target.com/a/${repoName}/observability/logs/${logParams}`}
        >
          <img src="/img/logs-icon.png" width="17px" height="17px" alt="Log icon" />
        </Anchor>
      </Tooltip>
      &nbsp;
    </div>
  )
}

export default ReleaseDashboardAppLinks
