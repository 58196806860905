import { createSlice } from '@reduxjs/toolkit'
import { PageStatus } from 'store/client'
import { IAssetState } from './types'

const initialState: IAssetState = {
  assetDetailPageAsset: undefined,
  assetDetailPageStatus: PageStatus.loading,
  assetUsers: [],
  assetCreationFileValues: [],
  userAssetPermissions: {
    userCanEditAndDeleteAsset: false,
    userIsAssetManager: false,
  },
  assetActivity: [],
  assetActivityIsLoading: false,
}

const assetSlice = createSlice({
  name: 'asset',
  initialState,
  reducers: {
    getAssetReducer(state, action) {},
    setAssetDetailPageStatus(state, action) {
      state.assetDetailPageStatus = action.payload
    },
    setAssetReducer(state, action) {
      state.assetDetailPageAsset = action.payload
    },
    createAssetReducer(state, action) {},
    addAssetFileStatusReducer(state, action) {
      state.assetCreationFileValues = [
        ...state.assetCreationFileValues,
        { name: action.payload.name, file: action.payload.file, status: 'QUEUED' },
      ]
    },
    setAssetFileStatusValueReducer(state, action) {
      state.assetCreationFileValues = state.assetCreationFileValues.map(fileValues => {
        if (fileValues.name === action.payload.name) {
          return { ...fileValues, status: action.payload.status }
        } else return fileValues
      })
    },
    clearAssetFileStatusValuesReducer(state) {
      state.assetCreationFileValues = []
    },
    editAssetReducer(state, action) {},
    addFilesToAssetReducer(state, action) {},
    deleteAssetReducer(state, action) {},
    getAssetUsersReducer(state, action) {},
    setAssetUsersReducer(state, action) {
      state.assetUsers = action.payload
    },
    setUserAssetPermissionsReducer(state, action) {
      state.userAssetPermissions.userCanEditAndDeleteAsset = action.payload.userCanEditAndDeleteAsset
      state.userAssetPermissions.userIsAssetManager = action.payload.userIsAssetManager
    },
    addAssetUserReducer(state, action) {},
    deleteAssetUserReducer(state, action) {},
    downloadAssetReducer(state, action) {},
    downloadAssetsReducer(state, action) {},
    downloadLinkedAssetReducer(state, action) {},
    setAssetActivityReducer(state, action) {
      state.assetActivity = action.payload
    },
    getAssetActivityReducer(state, action) {},
    setAssetActivityIsLoading(state, action) {
      state.assetActivityIsLoading = action.payload
    },
    process3DFileApiReducer(state, action) {},
  },
})

export const {
  getAssetReducer,
  setAssetReducer,
  setAssetDetailPageStatus,
  createAssetReducer,
  addAssetFileStatusReducer,
  setAssetFileStatusValueReducer,
  clearAssetFileStatusValuesReducer,
  editAssetReducer,
  addFilesToAssetReducer,
  deleteAssetReducer,
  getAssetUsersReducer,
  setAssetUsersReducer,
  setUserAssetPermissionsReducer,
  addAssetUserReducer,
  deleteAssetUserReducer,
  downloadAssetReducer,
  downloadAssetsReducer,
  downloadLinkedAssetReducer,
  setAssetActivityReducer,
  getAssetActivityReducer,
  setAssetActivityIsLoading,
  process3DFileApiReducer,
} = assetSlice.actions

export default assetSlice.reducer
