import { Grid, Layout, Dropdown, Button } from '@enterprise-ui/canvas-ui-react'
import { createSearchParams, Link, useSearchParams } from 'react-router-dom'
import SearchBar from 'components/SearchBar/SearchBar'
import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import './Header.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Classnames from 'classnames'
import ReactGA from 'react-ga'
import { TaxonomyNodeView } from 'gallerydigitalassets-v1-client'
// import FF from 'components/shared/FF'
import { useAuth, useSearch, useTaxonomy, processError } from 'store'
import { MODAL_TYPES } from 'config'
import { Tooltip } from '@enterprise-ui/canvas-ui-react'

type IHeaderDropdownMenuItem = {
  label: string
  pathname?: string
  hash?: string
  search?: string
}

type IHeaderDropdownProps = {
  items: IHeaderDropdownMenuItem[]
}

type IUserInfoProps = {
  userName: string
  companyName: string
}

const UserInfo: React.FC<IUserInfoProps> = ({ userName, companyName }) => {
  const { logOut } = useAuth()
  const [searchParams, setSearchParams] = useSearchParams()

  return (
    <div style={{ display: 'flex' }}>
      <div className="hc-mr-normal hc-mt-xs hc-mb-sm">
        <div className="hc-ta-right hc-clr-grey04 hc-fs-xs user-name">{userName}</div>
        <div className="hc-ta-right hc-clr-grey04 hc-fs-xs">{companyName}</div>
      </div>
      <div className="hc-mt-sm hc-mr-lg" style={{ position: 'relative' }}>
        <Tooltip content="Support" location="bottom">
          <Button
            type="destructive"
            iconOnly
            size="dense"
            className="hc-mr-dense"
            data-test-id="Prodpad Button"
            aria-label="Prodpad Button"
            onClick={() => {
              ReactGA.event({
                category: 'User',
                action: 'Clicked Prodpad Link',
              })
              searchParams.set('modal', MODAL_TYPES.HELP)
              setSearchParams(searchParams)
            }}
          >
            <FontAwesomeIcon icon="question" />
          </Button>
        </Tooltip>
        <Dropdown location="bottom-right">
          <Tooltip content="My Account" location="bottom-right" className="tooltip">
            <Button
              type="destructive"
              iconOnly
              size="dense"
              data-test-id="header-user-button"
              aria-label="header-user-button"
            >
              <FontAwesomeIcon icon="user" />
            </Button>
          </Tooltip>
          <Dropdown.Menu className="hc-pl-none hc-pr-none hc-mt-dense" style={{ width: '140px' }}>
            <Dropdown.MenuItem
              onClick={() => {
                ReactGA.event({
                  category: 'User',
                  action: 'Manually Logged Out',
                })
                console.log('Logging out')
                logOut()
                sessionStorage.clear()
              }}
              data-test-id="log-out"
            >
              Log out
            </Dropdown.MenuItem>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  )
}

const HeaderDropdown: React.FC<IHeaderDropdownProps> = ({ items }) => {
  const navigate = useNavigate()
  const location = useLocation()

  const [isVisible, setIsVisible] = useState<boolean>(false)
  const [mainLink, ...secondaryLinks] = items

  let mouseIntervalId: any
  const onMouseOver = () => {
    clearTimeout(mouseIntervalId)
    setIsVisible(true)
  }

  const onMouseOut = () => {
    mouseIntervalId = setTimeout(() => setIsVisible(false), 100)
  }

  return (
    <>
      <Dropdown onMouseOver={onMouseOver} onMouseOut={onMouseOut} className="header-main-link">
        <div style={{ height: '0px' }}></div>
        <Dropdown.Menu className={Classnames({ isVisible })}>
          {secondaryLinks.map(({ label, pathname, search }, index) => (
            <Dropdown.MenuItem
              key={index}
              onClick={() => {
                ReactGA.event({
                  category: 'Navigation',
                  action: 'Header Link Click',
                  label,
                })
                navigate({ pathname, search }, { state: { location } })
                setIsVisible(false)
              }}
              data-test-id={label}
            >
              {label}
            </Dropdown.MenuItem>
          ))}
        </Dropdown.Menu>
      </Dropdown>
      <Link
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
        to={{ pathname: mainLink.pathname, hash: mainLink.hash }}
        data-test-id={mainLink.label}
      >
        <span className="hc-fs-md">{mainLink.label}</span>
      </Link>
    </>
  )
}

const TaxonomyDropdown: React.FC<any> = ({ name }: { name: string }) => {
  const { getCategory, isLoading } = useTaxonomy()
  const { canUserCreateContent } = useAuth()

  const [category, setCategory] = useState<TaxonomyNodeView>()

  const grabCategory = async (name: string) => {
    try {
      const categories = (await getCategory(name)) as TaxonomyNodeView
      setCategory(categories)
    } catch (error) {
      if (error instanceof Error) {
        processError(error)
      } else {
        console.log(error)
      }
    }
  }

  useEffect(() => {
    if (!isLoading) {
      grabCategory(name)
    }
  }, [isLoading])

  const populateCategoryItems = () => {
    if (category) {
      const categoryItem: IHeaderDropdownMenuItem = {
        label: category?.name,
        pathname: `/category/${category?.name.toLowerCase()}`,
      }
      const categoryChildrenItems: IHeaderDropdownMenuItem[] = category.children!.map(
        (item: TaxonomyNodeView): IHeaderDropdownMenuItem => {
          return { label: item.name, pathname: `/subcategory/${item.name.toLowerCase()}` }
        }
      )

      const headerNavParams = {
        category: category.name.toLowerCase(),
        modal: MODAL_TYPES.ADD_ASSET,
      }

      const createCategoryType: IHeaderDropdownMenuItem = {
        label: `+ Add New ${category.name}`,
        search: `?${createSearchParams(headerNavParams)}`,
      }
      return canUserCreateContent()
        ? [categoryItem, ...categoryChildrenItems, createCategoryType]
        : [categoryItem, ...categoryChildrenItems]
    }
    return []
  }

  return (
    <>
      {!isLoading && category && (
        <Grid.Item className="hc-pv-none hc-mt-dense hc-ph-dense">
          <HeaderDropdown items={populateCategoryItems()} />
        </Grid.Item>
      )}
    </>
  )
}

const Header: React.FC = () => {
  const { getCategory, isLoading } = useTaxonomy()
  const [categories, setCategories] = useState<string[]>()
  const { isHomePage, isSearchPage } = useSearch()
  const { canUserCreateContent, userIdentifiers, isAdGroupMissing } = useAuth()
  const [userName, setUserName] = useState<string>('')
  const [companyName, setCompanyName] = useState<string>('')
  const navigate = useNavigate()
  useEffect(() => {
    if (userIdentifiers) {
      if (userIdentifiers.companyName) {
        setCompanyName(
          userIdentifiers.companyName!!.toLowerCase().includes('target ')
            ? 'Target'
            : userIdentifiers.companyName!!.length > 25
              ? userIdentifiers.companyName!!.slice(0, 25) + '...'
              : userIdentifiers.companyName!!
        )
      }
      if (userIdentifiers.displayName) {
        setUserName(
          userIdentifiers.displayName!!.length > 25
            ? userIdentifiers.displayName!!.slice(0, 25) + '...'
            : userIdentifiers.displayName!!
        )
      }
    }
  }, [userIdentifiers])

  const grabCategory = async (name: string) => {
    try {
      const categories = (await getCategory(name)) as TaxonomyNodeView
      setCategories(categories.children!!.map(category => category.name))
    } catch (error) {
      if (error instanceof Error) {
        processError(error)
      } else {
        console.log(error)
      }
    }
  }

  useEffect(() => {
    if (!isLoading) {
      grabCategory('Asset')
    }
    if (isAdGroupMissing()) {
      navigate('/error')
    }
  }, [isLoading])

  const populateCollectionMenuItems = () => {
    const collectionMenuItems: IHeaderDropdownMenuItem[] = [{ label: 'Collections', pathname: '/collections' }]
    collectionMenuItems.push({ label: 'Favorite Collections', pathname: '/collections/favorite' })
    if (canUserCreateContent()) {
      // collectionMenuItems.push({ label: 'Favorite Collections', pathname: '/collections/favorite' })
      collectionMenuItems.push({ label: 'My Collections', pathname: '/collections/my' })
    }

    collectionMenuItems.push({ label: 'Public Collections', pathname: '/collections/public' })

    const alphaSortedCollectionItems = collectionMenuItems.sort((a, b) => {
      switch (true) {
        case a.label > b.label:
          return 1
        case a.label < b.label:
          return -1
        default:
          return 0
      }
    })

    // Placing this here to keep links in specific order.
    if (canUserCreateContent()) {
      alphaSortedCollectionItems.push({ label: '+ Create New Collection', search: '?modal=create-collection' })
    }
    return alphaSortedCollectionItems
  }

  return (
    <Layout.Header includeRail={true}>
      <Grid.Container direction="row" align="center" justify="space-between" className="hc-pv-none ">
        <Grid.Item md={12} lg={6}>
          <Grid.Container direction="row" align="center" justify="flex-start" className="hc-pv-md">
            <Link to="/" data-test-id="header-logo" className="hc-mr-3x">
              <div className="header"></div>
            </Link>
            <Grid.Item className="hc-pv-none hc-mt-dense hc-ph-dense">
              {categories && <HeaderDropdown items={populateCollectionMenuItems()} />}
            </Grid.Item>
            {categories && categories.map((category, idx) => <TaxonomyDropdown key={idx} name={category} />)}
          </Grid.Container>
        </Grid.Item>
        <Grid.Item sm={12} md={12} lg={6}>
          <Grid.Container direction="row" align="flex-end" justify="flex-end">
            <UserInfo userName={userName} companyName={companyName} />
            {!isHomePage && !isSearchPage && (
              <SearchBar className="header-search" id="header-search-bar" showSelect={false} />
            )}
          </Grid.Container>
        </Grid.Item>
      </Grid.Container>
    </Layout.Header>
  )
}

export default Header
