import React, { useEffect, useState } from 'react'
import { useReleaseHook } from 'store'
import { Grid, Heading, Input, Spinner } from '@enterprise-ui/canvas-ui-react'
import { splitK6Cluster } from './ReleaseDashboardPageUtils'
import DashboardAppListing from './ReleaseDashboardPageListing'
import DashboardAppListHeader from './ReleaseDashboardPageListHeader'

const ReleaseDashboardPage: React.FC = () => {
  const { getReleaseInfo, releaseInfo } = useReleaseHook()
  const [clusters, setClusters] = useState(releaseInfo)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [autoRefresh, setAutoRefresh] = useState<boolean>(false)
  const [informationOverload, setInformationOverload] = useState<boolean>(true)

  useEffect(() => {
    getReleaseInfo()
  }, [])

  // Split 'galleryk6' cluster into 'galleryk6-gda' and 'galleryk6-search' clusters
  useEffect(() => {
    if (releaseInfo.length) {
      const updatedCluster =
        releaseInfo &&
        releaseInfo.flatMap(cluster => (cluster.name === 'galleryk6' ? splitK6Cluster(cluster) : cluster))
      setClusters(updatedCluster)
      setIsLoading(false)
    }
  }, [releaseInfo])

  useEffect(() => {
    let intervalId: NodeJS.Timeout
    if (autoRefresh) {
      intervalId = setInterval(() => {
        getReleaseInfo()
      }, 10000)
    }
    return () => {
      if (intervalId) clearInterval(intervalId)
    }
  }, [autoRefresh, getReleaseInfo])

  const handleAutoRefresh = () => {
    setAutoRefresh(!autoRefresh)
  }

  return (
    <>
      <Grid.Container>
        <Grid.Item xs={10} style={{ display: 'flex' }}>
          <Grid.Item xs={3}>
            <Heading size={3}>3D Gallery Deployment Status</Heading>
          </Grid.Item>
          <Grid.Item xs={2} className="hc-mb-dense">
            <Input.Toggle id="auto-refresh" label="Auto Refresh" checked={autoRefresh} onChange={handleAutoRefresh} />
          </Grid.Item>
        </Grid.Item>
        <Grid.Item xs={2}>
          <Grid.Container justify="flex-end">
            <Input.Checkbox
              id="information_Overload"
              label={<strong>Information Overload</strong>}
              onChange={() => {
                localStorage.setItem('informationOverload', informationOverload ? 'disabled' : 'enabled')
                setInformationOverload(!informationOverload)
              }}
              checked={!!informationOverload}
            />
          </Grid.Container>
        </Grid.Item>
      </Grid.Container>
      {isLoading ? (
        <Grid.Container justify="center" className="hc-pt-xl">
          <Spinner data-test-id="load-indicator" />
        </Grid.Container>
      ) : (
        <>
          <DashboardAppListHeader />
          <DashboardAppListing apps={clusters} informationOverload={informationOverload} />
        </>
      )}
    </>
  )
}

export default ReleaseDashboardPage
