import React from 'react'
import { Divider, Grid, Heading } from '@enterprise-ui/canvas-ui-react'
import { IReleaseInfo } from 'store'
import DashboardAppListItem from './ReleaseDashboardPageListItem'

interface DashboardAppListingProps {
  apps: IReleaseInfo[]
  informationOverload: boolean
}

export const DashboardAppListing: React.FC<DashboardAppListingProps> = ({ apps, informationOverload }) => {
  return (
    <React.Fragment>
      {apps?.length === 0 && <Heading size={4}>No apps found. Please check your configuration.</Heading>}
      {apps?.length > 0 && (
        <Grid.Container spacing={false}>
          {apps.map((cluster, index) => (
            <Grid.Item key={`app_${cluster.name}`} xs={12}>
              <DashboardAppListItem app={cluster} rowIndex={index} informationOverload={informationOverload} />
              <Divider />
            </Grid.Item>
          ))}
        </Grid.Container>
      )}
    </React.Fragment>
  )
}

export default DashboardAppListing
