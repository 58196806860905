import React from 'react'
import { IReleaseInfo } from 'store'
import EnterpriseIcon, { InfoFilledIcon } from '@enterprise-ui/icons'
import { Anchor, Grid, Tooltip } from '@enterprise-ui/canvas-ui-react'
import {
  getRepoNameFromProdCommit,
  renderClusterItem,
  renderPromotionDetails,
  VELA_BASE_URL,
} from './ReleaseDashboardPageUtils'

interface DashboardAppListItemProps {
  app: IReleaseInfo
  rowIndex: number
  informationOverload: boolean
}

const DashboardAppListItem: React.FC<DashboardAppListItemProps> = ({ app, informationOverload }) => {
  const devCluster =
    app.clusters && app.clusters['dev'] ? app.clusters['dev'].find(cluster => cluster.clusterName === app.name) : null

  const stageCluster =
    app.clusters && app.clusters['dev']
      ? app.clusters['dev'].find(cluster => cluster.clusterName?.includes('-stage'))
      : null

  const prodCluster = app.clusters && app.clusters['prod'] ? app.clusters['prod'][0] : null

  const repositoryName = getRepoNameFromProdCommit(prodCluster)
  const velaUrl = `${VELA_BASE_URL}${repositoryName}`

  return (
    <Grid.Container spacing="none">
      <Grid.Item className="hc-pa-dense" xs={2}>
        {app.name}
        <Tooltip location="top" tooltip={app.description || `${app.name} cluster.`}>
          <EnterpriseIcon className="hc-ml-min" icon={InfoFilledIcon} size="sm" />
        </Tooltip>
        {informationOverload && prodCluster?.commitLink && (
          <Grid.Item xs={12}>
            <Anchor href={velaUrl} target="_blank">
              Vela Build
            </Anchor>
          </Grid.Item>
        )}
      </Grid.Item>

      {renderClusterItem(app.name, devCluster, informationOverload)}
      <Grid.Item className="hc-pa-dense" xs={2}>
        {renderPromotionDetails(app.name, devCluster, stageCluster, 'Compare to Stage', 'Promote to Stage', 'stage')}
      </Grid.Item>
      {renderClusterItem(app.name, stageCluster, informationOverload)}
      <Grid.Item className="hc-pa-dense" xs={2}>
        {renderPromotionDetails(app.name, stageCluster, prodCluster, 'Compare to Prod', 'Promote to Prod', 'prod')}
      </Grid.Item>
      {renderClusterItem(app.name, prodCluster, informationOverload)}
    </Grid.Container>
  )
}

export default DashboardAppListItem
