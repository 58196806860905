import { AssetEditRequest, HttpError } from 'gallerydigitalassets-v1-client'
import { IAsset, IUser, axiosAssetActivityClient } from 'store'
import ReactGA from 'react-ga'
import { assetApi, assetDownloadApi, assetUserApi, galleryAxiosClient, digitalAssetUserApi } from '../client'
import { AddAssetUserAccessEnum } from '@galleryjs/api-digital-assets'
import axios from 'axios'
import { keysToCamel } from 'utils/object'

// TODO: MOVE THIS INTO CLIENT STORE AND MAKE ALTERABLE FROM ANY STORE FOR CANCELABLE CALLS
// This allows search to be cancelable if another search call is made
let call: any

export const getAssetApi = async (xTgtLanId: string, id: string) => {
  try {
    const { body } = await assetApi.getAsset(id, xTgtLanId)
    return body.data as unknown as IAsset
  } catch (error) {
    if (error instanceof HttpError) {
      throw error
    } else {
      console.error(error)
    }
  }
}

export const createAssetApi = async (
  xTgtLanId: string,
  name: string,
  file: File,
  addToPublic: boolean,
  taxonomyId: string,
  assetType: string,
  startPipelineOnAssetCreate: string
) => {
  const formData: FormData = new FormData()
  formData.append('file', file)
  formData.append('name', name)
  formData.append('add_to_public', addToPublic ? 'true' : 'false')
  formData.append('asset_type', assetType)
  formData.append('source', 'GALLERYWEBUI')
  formData.append('taxonomy_id', taxonomyId)
  formData.append('start_pipeline', startPipelineOnAssetCreate)
  try {
    const { data } = await galleryAxiosClient.post('/assets', formData)
    ReactGA.event({
      category: 'Asset',
      action: 'Asset Created',
    })
    return data.data
  } catch (error) {
    if (error instanceof Error) {
      throw error
    } else {
      console.error(error)
    }
  }
}

export const addFileToAssetApi = async (
  xTgtLanId: string,
  assetId: string,
  file: File,
  name: string,
  callTranscoding: Boolean
) => {
  // Derive classification.
  const fileExt: string = (file.name.split('.').pop() || '').toLowerCase()
  let classification: string
  switch (fileExt) {
    case 'glb':
    case 'fbx':
      classification = 'SOURCE_3D'
      break
    case 'png':
    case 'jpg':
    case 'gif':
      classification = 'NONE'
      // classification = 'REFERENCE'
      break
    default:
      classification = 'NONE'
  }

  const formData = new FormData()
  formData.append('file', file)
  formData.append('file_name', name)
  formData.append('classification', classification)
  formData.append('start_pipeline', callTranscoding ? 'true' : 'false')

  return await galleryAxiosClient.post(`/assets/${assetId}/files`, formData)
}

export const editAssetApi = async (xTgtLanId: string, assetId: string, request: AssetEditRequest) => {
  try {
    return await assetApi.editAsset(assetId, xTgtLanId, request)
  } catch (error) {
    if (error instanceof HttpError) {
      throw error
    } else {
      console.error(error)
    }
  }
}

export const deleteAssetApi = async (xTgtLanId: string, assetId: string) => {
  try {
    await assetApi.deleteAsset(assetId, xTgtLanId)
  } catch (error) {
    if (error instanceof HttpError) {
      throw error
    } else {
      console.error(error)
    }
  }
}

export const getAssetUsersApi = async (
  xTgtLanId: string,
  assetId: string,
  permission: 'CREATE' | 'VIEW' | 'EDIT' | 'DELETE' | 'LINK' | 'SHARE'
) => {
  try {
    const response = await assetUserApi.getAssetUsers(assetId, permission, xTgtLanId)
    if (response.body.data) {
      return response.body.data.map((item: any): IUser => {
        let user = {} as IUser
        user.id = item.user.id
        user.firstName = item.user.firstName
        user.lastName = item.user.lastName
        user.lanId = item.user.lanId
        user.mail = item.user.email
        user.access = item.access
        return user
      })
    }
  } catch (error) {
    if (error instanceof HttpError) {
      throw error
    } else {
      console.error(error)
    }
  }
}

export const addAssetUserApi = async (
  xTgtLanId: string,
  assetId: string,
  userId: string[],
  access:
    | 'NONE'
    | 'ADMIN'
    | 'ORGANIZATION_ADMIN'
    | 'WORKFLOW_ADMIN'
    | 'ORGANIZATION_ASSET_CREATOR'
    | 'ORGANIZATION_ASSET_MANAGER'
    | 'ORGANIZATION_ASSET_VIEWER'
    | 'ORGANIZATION_GALLERY_VIEWER'
    | 'ORGANIZATION_GALLERY_MANAGER'
    | 'ORGANIZATION_GALLERY_CREATOR'
    | 'GALLERY_CREATOR'
    | 'GALLERY_MANAGER'
    | 'GALLERY_VIEWER'
    | 'ASSET_CREATOR'
    | 'ASSET_MANAGER'
    | 'ASSET_VIEWER'
    | 'WORKFLOW_REVIEWER'
    | 'WORKFLOW_RENDER_CREATOR'
    | 'WORKFLOW_DEFAULT' = 'ASSET_MANAGER'
) => {
  try {
    await digitalAssetUserApi.addAssetUser({
      assetId: assetId,
      userId: userId,
      access: AddAssetUserAccessEnum.AssetManager,
      xTgtLANID: xTgtLanId,
    })
    // await assetUserApi.addAssetUser(assetId, userId[0], xTgtLanId, access)
  } catch (error) {
    if (error instanceof HttpError) {
      throw error
    } else {
      console.error(error)
    }
  }
}

export const deleteAssetUserApi = async (xTgtLanId: string, assetId: string, userId: string) => {
  try {
    await assetUserApi.deleteAssetUser(assetId, userId, xTgtLanId)
  } catch (error) {
    if (error instanceof HttpError) {
      throw error
    } else {
      console.error(error)
    }
  }
}

export const downloadAssetApi = async (xTgtLanId: string, assetId: string) => {
  if (call) {
    call.cancel('Cancelling earlier download request')
  }
  call = axios.CancelToken.source()

  const response = await galleryAxiosClient.get(`/assets/${assetId}/downloads`, {
    cancelToken: call.token,
  })
  const preSignedUrl = response.data.data.pre_signed_url
  const link = document.createElement('a')
  link.href = preSignedUrl
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
export const downloadAssetsApi = async (xTgtLanId: string, assetIds: string[]) => {
  if (call) {
    call.cancel('Cancelling earlier download request')
  }
  call = axios.CancelToken.source()
  await galleryAxiosClient.get(`assets/downloads?asset_ids=${assetIds}`, {
    cancelToken: call.token,
  })
}

export const downloadLinkedAssetsApi = async (xTgtLanId: string, assetId: string) => {
  if (call) {
    call.cancel('Cancelling earlier download request')
  }
  call = axios.CancelToken.source()
  try {
    await assetDownloadApi.download1(assetId, xTgtLanId, undefined)
  } catch (error) {
    if (error instanceof HttpError) {
      throw error
    } else {
      console.error(error)
    }
  }
}

export const getAssetActivityApi = async (assetActivityServer: string, assetId: string) => {
  try {
    const { data } = await axiosAssetActivityClient.get(`${assetActivityServer}/assets/${assetId}`)
    const camelCaseData = keysToCamel(data)
    //sorting activity from most recent to least recent
    return camelCaseData.data.sort(
      (a: { actionAt: string }, b: { actionAt: string }) =>
        new Date(b.actionAt).getTime() - new Date(a.actionAt).getTime()
    )
  } catch (error) {
    if (error instanceof HttpError) {
      throw error
    } else {
      console.error(error)
    }
  }
}

export const process3DFileApi = async (xTgtLanId: string, assetId: string[], pipelineId?: string) => {
  const requestBody = { asset_ids: assetId, pipeline_name: pipelineId }
  return await galleryAxiosClient.post(`/assets/pipelines`, requestBody)
}
