import { all, call, put, takeLatest } from 'redux-saga/effects'
import { getReleaseInfoApi } from './api'
import { STORAGE_KEYS } from 'config'
import { processError } from 'store'
import { IReleaseInfo } from './types'
import { getReleaseInfoReducer, setReleaseInfoReducer } from './slice'

function* getReleaseInfoSaga(action: any) {
  const xTgtLanId: string = localStorage.getItem(STORAGE_KEYS.TGT_LAN_ID)!!
  try {
    const releaseInfo: IReleaseInfo = yield call(getReleaseInfoApi, xTgtLanId)
    yield put(setReleaseInfoReducer(releaseInfo))
  } catch (error) {
    if (error instanceof Error) {
      yield call(processError, error, error.message)
    } else {
      console.log(error)
    }
  }
}

export default function* watchReleaseSagas() {
  yield all([takeLatest(getReleaseInfoReducer.type, getReleaseInfoSaga)])
}
