import { Grid, Spinner } from '@enterprise-ui/canvas-ui-react'
import { some, xor } from 'lodash'
import AssetResult from 'components/feeds/AssetFeed/AssetResult'
import InfiniteScroller from 'components/shared/InfiniteScroller'
import React, { useState, useEffect } from 'react'
import styles from './AssetFeed.module.scss'
import SearchPageZeroState from 'components/feeds/AssetFeed/SearchPageZeroState'
import CollectionDetailZeroState from 'components/feeds/AssetFeed/CollectionDetailZeroState'
import SubcategoryZeroState from 'components/feeds/AssetFeed/SubcategoryZeroState'
import { useSearch, IAsset, ZeroStateToShowEnum } from 'store'
import AssetFeedActions from './AssetFeedActions'

type AssetFeedProps = {
  isLoading: boolean
  assets?: IAsset[]
  totalCount?: number
  zeroStateToShow: ZeroStateToShowEnum
  infiniteScrollMethod?: () => void
  showAssetsLabel?: boolean
  pageName: string
  showResultsLabel?: boolean
  disableInfinite?: boolean
}

const AssetFeed: React.FC<AssetFeedProps> = ({
  isLoading,
  assets,
  totalCount,
  infiniteScrollMethod,
  showAssetsLabel,
  zeroStateToShow,
  pageName,
  showResultsLabel,
  disableInfinite = false,
}) => {
  const [checkedItems, setCheckedItems] = useState<string[]>([])
  const { isHomePage, searchCriteria } = useSearch()
  const [currentName, setName] = useState<string>(pageName)

  useEffect(() => {
    if (isLoading) {
      if (currentName !== pageName) {
        setCheckedItems([])
        setName(pageName)
      }
    }
  }, [isLoading])

  useEffect(() => {
    return () => {
      // Clear checked items on unmount.
      setCheckedItems([])
    }
  }, [searchCriteria.q])

  const zeroStateView = () => {
    let zeroState
    switch (zeroStateToShow) {
      case ZeroStateToShowEnum.COLLECTIONDETAILPAGE:
        zeroState = <CollectionDetailZeroState />
        break
      case ZeroStateToShowEnum.SEARCHPAGE:
        zeroState = <SearchPageZeroState />
        break
      case ZeroStateToShowEnum.SUBCATEGORYPAGE:
        zeroState = <SubcategoryZeroState />
        break
    }
    return zeroState
  }

  if (!assets) {
    return (
      <Grid.Container justify="center" className="hc-pt-xl">
        <Spinner data-test-id="load-indicator" />
      </Grid.Container>
    )
  }

  return (
    <Grid.Container className={`${styles['centered-content']} ${styles['heading']} hc-mh-expanded`} noWrap={false}>
      {/* HEADER SECTION FOR RESULTS HEADER AND FEED ACTIONS */}
      {!!totalCount && assets.length > 0 && (
        <Grid.Container justify="center" className={`${styles['container-width-control']}`}>
          <Grid.Item xs className="hc-ph-2x">
            <AssetFeedActions
              assets={assets}
              totalCount={totalCount}
              zeroStateToShow={zeroStateToShow}
              showAssetsLabel={showAssetsLabel}
              checkedItems={checkedItems}
              setCheckedItems={setCheckedItems}
              showResultsLabel={showResultsLabel}
            />
          </Grid.Item>
        </Grid.Container>
      )}

      {/* If assets object is empty, show spinner or zero state depending on loading state */}
      {!some(assets) && !isLoading && zeroStateView()}
      {!some(assets) && !!isLoading && (
        <Grid.Item xs={12}>
          <Grid.Container justify="center">
            <span data-test-id="assets-loading">
              <Spinner data-test-id="load-indicator" />
            </span>
          </Grid.Container>
        </Grid.Item>
      )}
      {/* if assets object is not empty, render assets, and infinite scroll card if isLoading */}
      {some(assets) && (
        <Grid.Container
          spacing="none"
          className={`${styles['search-asset-list-container']} ${styles['container-width-control']}`}
        >
          {assets.map((asset: IAsset, index: number) => (
            <AssetResult
              key={`${index}-asset-result`}
              asset={asset}
              checkedItems={checkedItems}
              isCheckable={true}
              onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                e.preventDefault()
                e.stopPropagation()
                setCheckedItems(xor(checkedItems, [asset.id]))
              }}
            />
          ))}
          {/* Infinite Scroller representation on loading state */}
          {!!totalCount && totalCount > assets.length && !!isLoading && (
            <>
              <Grid.Item xs={12} className="hc-pv-3x" data-test-id="asset-result">
                <Grid.Container justify="center">
                  <span>
                    <Spinner data-test-id="load-indicator" />
                  </span>
                </Grid.Container>
              </Grid.Item>
            </>
          )}
          {!!totalCount && totalCount > assets.length && !isLoading && !!infiniteScrollMethod && !disableInfinite && (
            <Grid.Item xs={12}>
              <InfiniteScroller nextPageCallback={infiniteScrollMethod} infiniteScrollerOffset={600} />
            </Grid.Item>
          )}
        </Grid.Container>
      )}
      {isHomePage && !isLoading && (
        <Grid.Item xs>
          <Grid.Container justify="center">
            <Grid.Item>
              <div className={`${styles['bottom-logo']} hc-mt-2x hc-mb-4x`} />
            </Grid.Item>
          </Grid.Container>
        </Grid.Item>
      )}
    </Grid.Container>
  )
}
export default AssetFeed
