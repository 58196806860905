import * as React from 'react'
import { useEffect, useState } from 'react'

import { Grid, Spinner, Heading, Button } from '@enterprise-ui/canvas-ui-react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import InfiniteScroller from 'components/shared/InfiniteScroller'
import CollectionResult from './CollectionResult'
import './CollectionFeed.scss'

import { ICollection, ICollectionSort, ICollectionResult, CollectionSet } from 'store'

type ICollectionFeedProps = {
  set?: CollectionSet
  numberOfCollectionsToRender?: number
  sort?: ICollectionSort
  collectionResult: ICollectionResult
  setNextPage: (page: number) => void
  getMoreCollections: (...args: any) => any
  collectionSet: CollectionSet
}

const CollectionFeed: React.FC<ICollectionFeedProps> = ({
  numberOfCollectionsToRender,
  collectionResult,
  set,
  getMoreCollections,
  setNextPage,
  collectionSet,
}) => {
  const location = useLocation()
  const navigate = useNavigate()
  const [totalCount, setTotalCount] = useState<number>(0)

  useEffect(() => {
    if (collectionResult) {
      setNextPage(collectionResult.criteria!.p + 1)
      setTotalCount(collectionResult.total!)
    }
  }, [collectionResult])

  const collectionsZeroState = () => {
    switch (set) {
      case CollectionSet.MY:
      case CollectionSet.PUB:
        return (
          <Grid.Item xs={4} className="noCollections hc-ta-center" data-testid="no-collections-zero-state">
            <div className="hc-pa-normal noCollectionCard hc-pa-xl">
              <Heading className="hc-clr-grey03 hc-pv-md" size={4}>
                You currently don't have any collections
              </Heading>
              <p className="hc-clr-grey03">
                Create your first <Link to={`${location.pathname}?p=0&q=&size=40?modal=create-collection`}>here</Link>.
              </p>
            </div>
          </Grid.Item>
        )

      case CollectionSet.FAV:
        return (
          <Grid.Item xs={6} className="noCollections hc-ta-center" data-testid="no-collections-zero-state">
            <div className="hc-pa-normal noCollectionCard hc-pa-xl">
              <Heading className="hc-clr-grey03 hc-pv-md" style={{ fontWeight: 'normal', paddingBottom: 15 }} size={2}>
                You currently have no Favorite Collections
              </Heading>
              <p className="hc-clr-grey03">
                <Button
                  data-testid="go-to-public-button"
                  onClick={() => {
                    navigate('/collections/public')
                  }}
                  type="secondary"
                >
                  Search Public Collections
                </Button>
              </p>
            </div>
          </Grid.Item>
        )
    }
  }
  return (
    <Grid.Container
      className={'collectionsFeed'}
      spacing="expanded"
      justify={collectionResult.feed && collectionResult.feed.length <= 0 ? 'center' : ''}
      direction="row"
    >
      {collectionResult?.feed?.length === 0 && collectionResult.isLoading && (
        <Grid.Container data-testid="feed-loading-spinner" justify="center" className="hc-pt-xl">
          <Spinner data-test-id="load-indicator" />
        </Grid.Container>
      )}
      {!collectionResult.isLoading && collectionResult.feed?.length <= 0 && collectionsZeroState()}

      {!!collectionResult?.feed.length &&
        !numberOfCollectionsToRender &&
        collectionResult.feed.map((collection: ICollection) => (
          <CollectionResult key={collection.id} collection={collection} collectionSet={collectionSet} />
        ))}
      {/* if numberOfCollectionsToRender prop passed in, only render that amount of results */}
      {!!collectionResult?.feed.length &&
        numberOfCollectionsToRender &&
        collectionResult.feed
          .slice(0, numberOfCollectionsToRender)
          .map((collection: ICollection) => (
            <CollectionResult key={collection.id} collection={collection} collectionSet={collectionSet} />
          ))}
      {collectionResult.feed.length > 0 && collectionResult.isLoading && (
        <Grid.Container data-testid="update-loading-spinner" justify="center" className="hc-pt-xl">
          <Spinner data-test-id="update-load-indicator" />
        </Grid.Container>
      )}
      {!!collectionResult.feed &&
        collectionResult.feed.length > 0 &&
        totalCount > collectionResult.feed.length &&
        !collectionResult.isLoading &&
        !numberOfCollectionsToRender && (
          <InfiniteScroller
            nextPageCallback={() => {
              getMoreCollections(15)
            }}
            infiniteScrollerOffset={500}
          />
        )}
    </Grid.Container>
  )
}

export default CollectionFeed
