import { useDispatch, useSelector } from 'react-redux'
import { getReleaseInfoReducer } from './slice'
import { releaseInfoSelector } from './selectors'

export const useReleaseHook = () => {
  const releaseInfo = useSelector(releaseInfoSelector)
  const dispatch = useDispatch()

  const getReleaseInfo = async () => {
    dispatch(getReleaseInfoReducer())
  }

  return {
    getReleaseInfo,
    releaseInfo,
  }
}
