import {
  IAsset,
  IAssetESSearch,
  IAssetFileESSearch,
  IAssetFileSourceESSearch,
  IAssetTaxonomy,
  IFilterChip,
  IFilterItem,
  IFilterValue,
  ISearchSort,
} from 'store'
import axios from 'axios'
import ReactGA from 'react-ga'
import { keysToCamel } from 'utils/object'
import { axiosSearchClient } from '../client'
import { FileSourceView, FileView, HttpError } from 'gallerydigitalassets-v1-client'
import { ICustomFilterValue, IESSearchFilter, ISearchRequest } from './types'
import { filter } from 'lodash'

// TODO: MOVE THIS INTO CLIENT STORE AND MAKE ALTERABLE FROM ANY STORE FOR CANCELABLE CALLS
// This allows search to be cancelable if another search call is made
let call: any

// Creates a cancellable search query, so only one query is active
const searchAssetsOnce = async (
  query: string,
  filters: IFilterChip,
  page: number = 0,
  size: number = 25,
  sort: string[] = [],
  funkyFilters: { [key: string]: ICustomFilterValue[] },
  server: string,
  key: string,
  taxonomy?: string,
  searchSort?: ISearchSort[]
) => {
  if (call) {
    call.cancel('Cancelling earlier search request')
  }
  call = axios.CancelToken.source()

  ReactGA.event({
    category: 'Search',
    action: 'Header Search',
    label: query,
  })

  const requestBody = getSearchRequestBody(query, filters, taxonomy, searchSort)

  // return await axiosSearchClient.post(
  //   `${server}/search/assets?per_page=${size}&page=${page}&pre_sign_all_files=false`,
  //   requestBody
  // )
  return await axiosSearchClient.post(
    `${server}/search/paginated/assets?per_page=${size}&exclude_fields=asset_tags&pre_sign_all_files=false`,
    requestBody
  )
}

export const searchAssetsApi = async (
  query: string,
  page: number = 0,
  filters: IFilterChip = {},
  size: number = 25,
  sort: string[] = [],
  funkyFilters: { [key: string]: ICustomFilterValue[] },
  server: string,
  key: string,
  taxonomy?: string,
  searchSort?: ISearchSort[]
) => {
  try {
    const defaultTaxonomy: IAssetTaxonomy = {
      id: '',
      name: '',
      services: [],
      parent: undefined,
    }
    const { data } = await searchAssetsOnce(
      query,
      filters,
      page,
      size,
      sort,
      funkyFilters,
      server,
      key,
      taxonomy,
      searchSort
    )

    // Coerce data keys into camel case (to match TS & gallery-api-client expectations)
    const dataToCamel = keysToCamel(data)
    const searchAssets: IAsset[] = []
    const formattedSearchAssetResult: IAssetESSearch[] = dataToCamel.searchResults as unknown[] as IAssetESSearch[]
    formattedSearchAssetResult.forEach((searchedESAsset: IAssetESSearch) => {
      let searchedIAssetFiles: FileView[] = []
      searchedESAsset.files.forEach((searchESAssetFile: IAssetFileESSearch) => {
        let fileSources: FileSourceView[] = []

        let heroImage: boolean = false
        searchESAssetFile.sources.forEach((source: IAssetFileSourceESSearch) => {
          let fileSource: FileSourceView = {
            accessUrl: source.accessUrl,
            fileExtension: source.fileExtension,
            fileName: source.fileName,
            fileType: '',
            id: '',
            image: false,
            md5Hash: '',
            tossBucketId: '',
            tossKey: source.s3Key,
          }
          if (source.heroImage) {
            heroImage = true
          }
          fileSources.push(fileSource)
        })
        let file: FileView = {
          classification: searchESAssetFile.sources[0].fileClassification as unknown as FileView.ClassificationEnum,
          createdAt: new Date(),
          fileSources: fileSources,
          fileTags: [],
          heroImage: heroImage,
          id: '',
          name: '',
          original: false,
          updatedAt: new Date(),
        }
        searchedIAssetFiles.push(file)
      })

      const heroImageFiles = filter(searchedIAssetFiles, 'heroImage')
      if (searchedESAsset.tcinThumbnail && searchedESAsset.tcinThumbnail.accessUrl.trim().length > 0) {
        let tcinFile: FileView = {
          classification: FileView.ClassificationEnum.TcinThumbnail,
          createdAt: new Date(),
          fileSources: [
            {
              accessUrl: searchedESAsset.tcinThumbnail.accessUrl,
              fileExtension: searchedESAsset.tcinThumbnail.fileExtension,
              fileName: searchedESAsset.tcinThumbnail.fileName,
              fileType: searchedESAsset.tcinThumbnail.fileExtension,
              id: '',
              image: true,
              md5Hash: '',
              tossBucketId: '',
              tossKey: '',
            },
          ],
          fileTags: [],
          heroImage: true,
          id: '',
          name: '',
          original: false,
          updatedAt: new Date(),
        }
        searchedIAssetFiles.push(tcinFile)
        if (heroImageFiles.length === 0) {
          heroImageFiles.push(tcinFile)
        }
      }

      let searchedIAsset: IAsset = {
        active: true,
        assetOrganizations: [],
        assetTags: [],
        assetTaxonomy: defaultTaxonomy,
        assetType: 'NONE',
        assetUsers: [],
        createdAt: 0,
        files: searchedIAssetFiles,
        heroImageFile: heroImageFiles[0],
        id: searchedESAsset.id,
        name: searchedESAsset.name,
        permissions: [],
        sourceId: '',
        taxonomyId: defaultTaxonomy.id,
        updatedAt: 0,
      }
      searchAssets.push(searchedIAsset)
    })
    const totalCount = dataToCamel.totalItems
    return { searchAssets, totalCount, sort: dataToCamel.sort }
  } catch (error) {
    if (error instanceof Error) {
      throw error
    } else {
      console.error(error)
    }
  }
}

// FETCHES TOPICAL FILTERS BASED ON CURRENT SEARCH
export const getFiltersApi = async (
  currentSearchTab: string,
  supportedFilters: string[],
  server: string,
  key: string,
  query: string,
  filters: IFilterChip
) => {
  try {
    const requestBody = getSearchFiltersRequestBody(query, filters, currentSearchTab)
    const bodyFilterSearch = await (await axiosSearchClient.post(`${server}/search/assets/filters`, requestBody)).data

    if (bodyFilterSearch.data) {
      const searchedFilters = Object.entries(bodyFilterSearch.data.filters)
      return searchedFilters
        .filter(([key, values]) => supportedFilters.includes(key.toLowerCase()))
        .map(([key, values]) => {
          let filterItem = {} as IFilterItem
          filterItem.key = key
          filterItem.value = (values as string[]).map((it: any) => {
            return { filterValue: it } as IFilterValue
          })
          return filterItem
        })
    }
  } catch (error) {
    if (error instanceof HttpError) {
      throw error
    } else {
      console.error(error)
    }
  }
}

const getSearchRequestBody = (query: string, filters: IFilterChip, taxonomy?: string, searchSort?: ISearchSort[]) => {
  const reducedFilters: IESSearchFilter[] = []
  const sort: { field: String; direction: String; field_value: String | undefined }[] = []
  let searchQuery: string = query
  if (filters) {
    Object.keys(filters).forEach(filterSection => {
      //Iterating over every filter in the request and checking to see if it's an item identifier
      const currentFilters = filters[filterSection]
      if (filterSection !== 'item_identifiers') {
        let newFilter: IESSearchFilter = {
          filter_chip_key: filterSection,
          filter_chip_value: currentFilters,
        }
        reducedFilters.push(newFilter)
      } else {
        // If the currentFilter is an item identifier we want to use the actual item identifier as the key vs using the "filterSection" value.

        searchQuery = ''

        currentFilters.forEach(cf => {
          let newFilter: IESSearchFilter = {
            filter_chip_key: cf,
            filter_chip_value: query.split(','),
          }
          reducedFilters.push(newFilter)
        })
      }
    })
  }

  if (searchSort) {
    searchSort.forEach(s => {
      let searchSort = {
        field: s.field,
        direction: s.direction,
        field_value: s.fieldValue,
      }
      sort.push(searchSort)
    })
  }

  const requestBody: ISearchRequest = {
    query: searchQuery,
    filters: reducedFilters.length > 0 ? reducedFilters : undefined,
    taxonomies: undefined,
    sort: sort,
  }

  if (taxonomy && taxonomy !== 'All') {
    requestBody.taxonomies = taxonomy?.split(',')
  }
  return requestBody
}

const getSearchFiltersRequestBody = (query: string, filters: IFilterChip, taxonomy?: string) => {
  const reducedFilters: IESSearchFilter[] = []
  let searchQuery: string = query
  if (filters) {
    Object.keys(filters).forEach(filterSection => {
      //Iterating over every filter in the request and checking to see if it's an item identifier
      const currentFilters = filters[filterSection]
      if (filterSection !== 'item_identifiers') {
        let newFilter: IESSearchFilter = {
          filter_chip_key: filterSection,
          filter_chip_value: currentFilters,
        }
        reducedFilters.push(newFilter)
      } else {
        // If the currentFilter is an item identifier we want to use the actual item identifier as the key vs using the "filterSection" value.

        searchQuery = ''

        currentFilters.forEach(cf => {
          let newFilter: IESSearchFilter = {
            filter_chip_key: cf,
            filter_chip_value: query.split(','),
          }
          reducedFilters.push(newFilter)
        })
      }
    })
  }

  const requestBody: ISearchRequest = {
    query: searchQuery,
    filters: reducedFilters.length > 0 ? reducedFilters : undefined,
    taxonomies: undefined,
    sort: undefined,
  }

  if (taxonomy && taxonomy !== 'All') {
    requestBody.taxonomies = taxonomy?.split(',')
  }
  return requestBody
}
